import la from '../LazyComponent';

const DeviceBind = la(() => import('@/pages/BindDevice'));
const ChangeDevice = la(() => import('@/pages/ChangeDevice'));

const GoogleFaRoutes = [
  {
    path: '/bindDevice',
    element: DeviceBind,
    name: 'menu.DeviceBind',
  },
  {
    path: '/changeDevice',
    element: ChangeDevice,
    name: 'menu.ChangeDevice',
  },
];

export default GoogleFaRoutes;
