import la from '../LazyComponent';

const Setting = la(() => import('@/pages/Administration/GroupSetting'));
const TemplateSetting = la(() => import('@/pages/Administration/TemplateSetting'));
const ClientTier = la(() => import('@/pages/Administration/ClientTierManagement'));
const CallRouteManagement = la(() => import('@/pages/Administration/CallRouteManagement'));
const MessagePush = la(() => import('@/pages/Administration/MessagePushManagement'));

const SettingRoutes = [
  {
    path: '/setting',
    element: Setting,
    meta: {
      key: 'menu.sws.admin',
    },
  },
  {
    path: '/setting/groupSetting',
    element: Setting,
    name: 'menu.Workgroup Management',
    meta: {
      key: 'menu.sws.admin.workGroup',
    },
  },
  {
    path: '/setting/templateSetting',
    element: TemplateSetting,
    name: 'menu.Template Management',
    meta: {
      key: 'menu.sws.admin.template',
    },
  },
  {
    path: '/setting/clientTierManagement',
    element: ClientTier,
    name: 'menu.clientTierManagement',
    meta: {
      key: 'menu.sws.admin.client_tier',
    },
  },
  {
    path: '/setting/clientRouteManagement',
    element: CallRouteManagement,
    name: 'menu.clientRouteManagement',
    meta: {
      key: 'menu.sws.admin.call_routing',
    },
  },
  {
    path: '/setting/messagePushManagement',
    element: MessagePush,
    name: 'menu.messagePushManagement',
    meta: {
      key: 'menu.sws.admin.message_push',
    },
  },
];

export default SettingRoutes;
