import { createModel } from '@rematch/core';
import type { RootModel } from '.';

export const time = createModel<RootModel>()({
  state: {
    ibSearchTime: [],
    cpaSearchTime: [],
  },
  reducers: {
    setIbSearchTime: (state, payload) => {
      return {
        ...state,
        ibSearchTime: payload,
      };
    },
    setCpaSearchTime: (state, payload) => {
      return {
        ...state,
        cpaSearchTime: payload,
      };
    },
  },
  effects: (dispatch) => ({}),
});
