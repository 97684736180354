import la from '../LazyComponent';

const Profile = la(() => import('@/pages/Profile'));
const CpaManagement = la(() => import('@/pages/PartnerManagement/CpaManagement'));
const IbManagement = la(() => import('@/pages/PartnerManagement/IbManagement'));

const PartnerManagement = [
  {
    path: '/partnerManagement',
    element: CpaManagement,
    meta: {
      key: 'sws.v2.partner-management',
    },
  },
  {
    path: '/partnerManagement/ibManagement',
    element: IbManagement,
    name: 'menu.IbManagement',
    meta: {
      key: 'sws.v2.ib-management',
    },
  },
  {
    path: '/partnerManagement/cpaManagement',
    element: CpaManagement,
    name: 'menu.CpaManagement',
    meta: {
      key: 'sws.v2.cpa-management',
    },
  },
  {
    path: '/profile/infomation/:id',
    element: Profile,
    meta: {
      key: 'sws.v2.ib-management',
    },
  },
];

export default PartnerManagement;
