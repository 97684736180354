import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { getLocalRcLoginStatus } from '@/utils/ringCentral';

export const phoneTool = createModel<RootModel>()({
  state: {
    rcAuthMessage: {
      clientId: '',
      clientSecret: '',
    },
    showRCbutton: !getLocalRcLoginStatus(), // 默认显示
    showNXCloudButton: true,
    NXCloudVisible: false,
    NXCloudAccount: '',
    callOut: '',
  },
  reducers: {
    setRcAuthMessage: (state, payload) => {
      return {
        ...state,
        rcAuthMessage: payload,
      };
    },
    setShowRCbutton: (state, payload) => {
      return {
        ...state,
        showRCbutton: payload,
      };
    },
    setShowNXCloudButton: (state, payload) => {
      return {
        ...state,
        showNXCloudButton: payload,
      };
    },

    setCallOut: (state, payload) => {
      return {
        ...state,
        callOut: payload,
      };
    },
    setNXCloudVisible: (state, payload) => {
      return {
        ...state,
        NXCloudVisible: payload,
      };
    },
    setNXCloudAccount: (state, payload) => {
      return {
        ...state,
        NXCloudAccount: payload,
      };
    },
  },
  effects: (dispatch) => ({}),
});
