import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import la from './LazyComponent';
import ErrorBoundary from './ErrorBoundary';
import taskManagementRoutes from './modules/taskManagement';
import SalesWorkStationRoutes from './modules/salesWorkStation';
import LeadManagementRoutes from './modules/leadManagement';
import ClientManagementRoutes from './modules/clientsManagement';
import MessagesRoutes from './modules/messages';
import AdminStatisticsRoutes from './modules/adminStatistic';
import SalesNoteRoutes from './modules/salesNote';
import ReportRoutes from './modules/reports';
import SettingRoutes from './modules/administration';
import GoogleFaRoutes from './modules/googleFa';
import PartnerManagement from './modules/partnerManagement';

const MainLayout = la(() => import('@/components/MainLayout'));
const NotFound = la(() => import('@/pages/404'));
const NoAuth = la(() => import('@/pages/403'));
const Login = la(() => import('@/pages/Login'));
const OtherEntry = la(() => import('@/pages/OtherEntry'));
const RingCentralTest = la(() => import('@/pages/RingCentralCallTest'));
const EmailTransferPage = la(() => import('@/pages/EmailTransferPage'));
const testPage = la(() => import('@/pages/TestPages'));

const config = createBrowserRouter([
  {
    path: '/',
    element: MainLayout,
    errorElement: <ErrorBoundary />,
    // exact: true,
    children: [
      // has header and sidebar layouts
      ...taskManagementRoutes,
      ...SalesWorkStationRoutes,
      ...LeadManagementRoutes,
      ...ClientManagementRoutes,
      ...MessagesRoutes,
      ...AdminStatisticsRoutes,
      ...SalesNoteRoutes,
      ...ReportRoutes,
      ...SettingRoutes,
      ...GoogleFaRoutes,
      ...PartnerManagement,
      {
        path: '/test/call',
        element: RingCentralTest,
      },
      {
        path: '/403',
        element: NoAuth,
      },
      {
        path: '/test',
        element: testPage,
      },
    ],
  },
  {
    path: '*',
    element: NotFound,
  },
  {
    path: '/login',
    element: Login,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/otherEntry',
    element: OtherEntry,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/transfer',
    element: EmailTransferPage,
  },
]);

export default config;
